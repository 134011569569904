import { css } from '@emotion/css';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import demoData from '../demo-data.json';
import montisLogo from '../images/montis-corp-full-logo.png';
import { useStore } from '../state';
import HelpIcon from './icons/Help';

const azimuthStyle = css({
  position: 'absolute',
  width: '100px',
  height: 75,
  right: 'calc(100%/2 - 50px)',
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center',
  alignItems: 'center',
  padding: '5px 15px',
  backgroundColor: '#ffffffcc',
  borderRadius: '0 0 5px 5px',
  'span:nth-child(2)': {
    fontSize: '1.75em',
  },
  '@media (max-width: 800px)': {
    top: 75,
    right: 0,
  },
});

const AzimuthIndicator = ({ azimuth }) => {
  return (
    <div className={azimuthStyle}>
      <span>Azimuth</span>
      <span>{parseFloat(azimuth).toFixed(1)}°</span>
    </div>
  );
};

const altitudeStyle = css({
  position: 'absolute',
  width: '100px',
  height: 75,
  // left: 'calc(100vw/2 - 100px)',
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center',
  alignItems: 'center',
  padding: '5px 15px',
  backgroundColor: '#ffffffcc',
  borderRadius: '0 0 5px 5px',
  'span:nth-child(2)': {
    fontSize: '1.75em',
  },
  '@media (max-width: 800px)': {
    borderRadius: 0,
  },
});

const ElevationIndicator = ({ elevation }) => {
  return (
    <div className={altitudeStyle}>
      <span>Elevation</span>
      <span>{parseFloat(elevation).toFixed(1)}°</span>
    </div>
  );
};

const stationInfoStyle = css({
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor: '#ffffffca',
  padding: '5px 15px',
  borderBottomRightRadius: '5px',
  display: 'flex',
  flexDirection: 'column',
  width: 420,
  '.weather-string': {
    fontSize: '.85em',
  },
  '.header': {
    // height: 40,
    fontSize: '1.25em',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    // marginBottom: 2,
    '.header-left': {
      display: 'flex',
      flexDirection: 'column',
      width: '66%',
      '.site-name': {
        marginBottom: 2,
        fontSize: '1.25em',
      },
    },
    '.header-right': {
      width: '33%',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      paddingTop: 3,
      position: 'relative',
      '.help': {
        '.help-text': {
          display: 'none',
          position: 'absolute',
          backgroundColor: '#fafaff',
          fontSize: '.85em',
          width: 350,
          right: -130,
          padding: '15px 25px',
          marginTop: 3,
          borderRadius: 5,
        },
        ':hover': {
          '.help-text': {
            display: 'initial',
          },
        },
      },
    },
  },
  // Tablet
  '@media (max-width: 1200px)': {
    width: 365,
    '.header': {
      fontSize: '1.15em',
    },
  },
  // Phone
  '@media (max-width: 800px)': {
    width: 250,
    '.header': {
      fontSize: '1em',
    },
    '.weather-string': {
      fontSize: '.75em',
    },
  },
});

type Observation = {
  rawText: string;
};

type MwosDetails = {
  siteName: string;
  observations: Observation[];
};

const getStationInfo = async (stationId): Promise<MwosDetails> => {
  const req = await fetch(`https://api.montiscorp.com/mwos/${stationId}`, {
    headers: {
      authorization: 'VESTUG2IIGDKKCDJFDQC6ZZAODETADWB',
    },
  });
  const json = await req.json();
  // console.log(json);
  return json;
};

type Props = {
  observation: {
    dewpointC: number;
    humidityPct: number;
    observationTime: string;
    precipIn: number;
    precipType: string;
    pressureHpa: number;
    rawText: string;
    tempC: number;
    wetbulbTempC: number;
    windDirDegrees: string;
    windGustKt: number;
    windSpeedKt: number;
    windsText: string;
  };
};

export const minutesAgo = (datetime: string) => {
  const now = DateTime.now();
  const then = DateTime.fromISO(datetime);
  const minutesAgo = Math.round(now.diff(then).as('minutes'));
  return minutesAgo;
};

export const displayDatetime = (datetime: string) => {
  const then = DateTime.fromISO(datetime);
  return then.toLocaleString(DateTime.DATETIME_SHORT);
};

const DecodedWeather = ({ observation }: Props) => {
  const Row = ({ label, value }) => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        fontSize: 13,
      }}
    >
      <div
        style={{
          width: '45%',
          textAlign: 'right',
          verticalAlign: 'middle',
          paddingRight: 5,
          fontWeight: 600,
        }}
      >
        {label}
      </div>
      <div
        style={{
          width: '50%',
          textAlign: 'left',
          verticalAlign: 'middle',
          paddingLeft: 5,
        }}
      >
        {value}
      </div>
    </div>
  );

  const c2f = (c) => (c * (9.0 / 5.0) + 32).toFixed(1);
  const fmtC = (deg) => `${parseFloat(deg).toFixed(2)} °C (${c2f(deg)} °F)`;

  return (
    <div>
      <Row
        label="Observation Time"
        value={`${displayDatetime(observation.observationTime)} (${minutesAgo(
          observation.observationTime
        )} min ago)`}
      />
      <Row label="Temperature" value={fmtC(observation.tempC)} />
      <Row label="Dew Point" value={fmtC(observation.dewpointC)} />
      <Row label="Pressure" value={`${observation.pressureHpa} inHg`} />
      <Row label="Winds" value={observation.windsText} />
      <Row label="Humidity" value={`${observation.humidityPct.toFixed(2)} %`} />
      <Row
        label="Wet Bulb Temperature"
        value={fmtC(observation.wetbulbTempC)}
      />
      <Row label="Precipitation Type" value={observation.precipType} />
      <Row label="Precipitation Rate" value={observation.precipIn} />
    </div>
  );
};

const isMobile = () => window.outerWidth < 800;
const montisBlue = '#1b456d';

const StationInfo = ({ stationId, isDemo }) => {
  const [data, setData] = useState<MwosDetails>();
  const [decodedVisible, setDecodedVisible] = useState<boolean>(!isMobile());

  useEffect(() => {
    const getData = async () => {
      if (isDemo) {
        setData(demoData);
        return;
      }
      const data = await getStationInfo(stationId);
      setData(data);
    };
    getData();
    const refreshInterval = setInterval(getData, 60e3);
    return () => {
      clearInterval(refreshInterval);
    };
  }, [stationId]);

  const toggleDecodedVisible = useCallback(
    () => setDecodedVisible((x) => !x),
    []
  );

  if (!data) {
    return false;
  }

  return (
    <div className={stationInfoStyle}>
      <div className="header">
        <div className="header-left">
          <span className="site-name">{data.siteName}</span>
        </div>

        <div className="header-right">
          <Attribution />
          <div className="help">
            <HelpIcon width={24} height={24} />
            <div className="help-text">
              <p>
                Weather data is automatically refreshed approximately once every
                10 minutes. All values are averages over the previous 10-minute
                period.
              </p>
            </div>
          </div>
        </div>
      </div>
      <span className="weather-string">{data.observations[0].rawText}</span>
      <span
        onClick={toggleDecodedVisible}
        style={{
          color: montisBlue,
          fontWeight: 600,
          textAlign: 'center',
          fontSize: 14,
          paddingTop: 5,
          cursor: 'pointer',
        }}
      >
        {decodedVisible ? 'Hide' : 'Show'} decoded weather
      </span>
      {decodedVisible && (
        <div
          style={{ marginTop: 7, borderTop: '1px solid #aaa', paddingTop: 7 }}
        >
          <DecodedWeather observation={data.observations[0]} />
        </div>
      )}
    </div>
  );
};

const imageFetchModeStyle = css({
  display: 'none',
  position: 'absolute',
  top: 125,
  left: 0,
  backgroundColor: '#ffffffcc',
  padding: '5px 15px',
  borderBottomRightRadius: '5px',
  // display: 'flex',
  flexDirection: 'column',
  width: 200,
  select: {
    margin: '5px 0',
  },
});

const ImageFetchMode = ({ mode, onChange }) => {
  return (
    <div className={imageFetchModeStyle}>
      <span>Image Fetch Mode</span>
      <select onChange={onChange} defaultValue={mode}>
        <option value="single" disabled>
          Single
        </option>
        <option value="foveated">Foveated</option>
        <option value="all" disabled>
          All
        </option>
      </select>
    </div>
  );
};

const attributionStyle = css({
  left: 0,
  height: 38,
  padding: '0px 0px',
  borderTopRightRadius: 5,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: 5,
  a: {
    textDecoration: 'none',
    color: 'rgb(27, 69, 109)',
    fontWeight: 500,
    fontSize: '.45em',
  },
  img: {
    aspectRatio: 2.5,
    height: 32,
  },
});

const Attribution = () => {
  return (
    <div className={attributionStyle}>
      <a href="https://montiscorp.com" target="_blank" rel="noreferrer">
        <img src={montisLogo} />
      </a>
    </div>
  );
};

const style = css({
  fontFamily: 'Open Sans',
});

const DemoWarning = () => {
  return (
    <div
      style={{
        position: 'absolute',
        bottom: 50,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <span
        style={{
          color: 'white',
          fontWeight: 600,
          fontSize: '1.4em',
          backgroundColor: '#00000088',
          padding: '10px 20px',
          borderRadius: 3,
          textAlign: 'center',
        }}
      >
        This page is for demonstration purposes only. Images and weather data
        are not current.
      </span>
    </div>
  );
};

type ControlsProps = {
  stationId: number;
  showStationInfo: boolean;
  showAzimuthIndicator: boolean;
  showElevationIndicator: boolean;
  bearing: number;
  elevation: number;
  isDemo?: boolean;
};

export default function Controls({
  stationId,
  showStationInfo,
  showAzimuthIndicator,
  showElevationIndicator,
  bearing,
  elevation,
  isDemo,
}: ControlsProps) {
  const fetchMode = useStore((state) => state.fetchMode);
  const setFetchMode = useStore((state) => state.setFetchMode);
  return (
    <div className={style}>
      {showStationInfo && <StationInfo stationId={stationId} isDemo={isDemo} />}
      {showAzimuthIndicator && <AzimuthIndicator azimuth={bearing} />}
      {showElevationIndicator && <ElevationIndicator elevation={elevation} />}
      <ImageFetchMode mode={fetchMode} onChange={setFetchMode} />
      {/* <Attribution /> */}
      {isDemo && <DemoWarning />}
    </div>
  );
}
