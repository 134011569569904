import { Config } from '../types';

import cam1 from '../images/merrill/1.avif';
import cam2 from '../images/merrill/2.avif';
import cam3 from '../images/merrill/3.avif';
import cam4 from '../images/merrill/4.avif';

const config: Config = {
  baseUrl: 'https://pano.montiscorp.com/img',
  title: 'Merrill Field MWOS Panorama - Montis Corp',
  defaultLookAngle: 0,
  azimuthOffset: -90,
  defaultFov: 70,
  latitude: 61.214214,
  longitude: -149.842739,
  stationId: 1,
  controls: {
    showStationInfo: true,
    showAzimuthIndicator: true,
    showElevationIndicator: true,
  },
  refresh: {
    mode: 'foveated',
    intervalMs: 2000,
  },
  cameras: {
    camera1: {
      defaultImage: cam1,
      azimuthAngle: 0,
      uniforms: {
        rotation: { value: -0.04 },
        vshift: { value: -0.02 },
        hshift: { value: 0.028 },
        brighten: { value: 1.17 },
        blueAdjust: { value: 1.0 },
        redAdjust: { value: 1.0 },
      },
    },
    camera2: {
      defaultImage: cam2,
      azimuthAngle: 90,
      uniforms: {
        rotation: { value: -0.032 },
        vshift: { value: -0.01 },
        hshift: { value: 0.012 },
        brighten: { value: 1.16 },
        blueAdjust: { value: 0.98 },
        redAdjust: { value: 1.0 },
      },
    },
    camera3: {
      defaultImage: cam3,
      azimuthAngle: 180,
      uniforms: {
        rotation: { value: 0.025 },
        vshift: { value: 0.017 },
        hshift: { value: -0.025 },
        brighten: { value: 1.1 },
        blueAdjust: { value: 0.94 },
        redAdjust: { value: 1.0 },
      },
    },
    camera4: {
      defaultImage: cam4,
      azimuthAngle: 270,
      uniforms: {
        rotation: { value: 0.007 },
        vshift: { value: 0.0051 },
        hshift: { value: -0.0016 },
        brighten: { value: 1.05 },
        blueAdjust: { value: 0.96 },
        redAdjust: { value: 1.0 },
      },
    },
  },
  labels: [
    {
      name: 'Ship Creek Hill',
      elevation: 1172,
      latitude: 61.2067,
      longitude: -149.561,
    },
    {
      name: 'Wolverine Peak',
      elevation: 1369,
      latitude: 61.135,
      longitude: -149.61401,
    },
    {
      name: 'Hope Point',
      elevation: 1102,
      latitude: 60.9369,
      longitude: -149.71599,
    },
    {
      name: 'Flattop Mountain',
      elevation: 1070,
      latitude: 61.0897,
      longitude: -149.669,
    },
    {
      name: 'Rendezvous Peak',
      elevation: 1240,
      latitude: 61.25012,
      longitude: -149.50637,
    },
    {
      name: 'Roundtop',
      elevation: 1459,
      latitude: 61.3431,
      longitude: -149.41599,
    },
  ],
};

export default config;
