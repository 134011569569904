{
  "cameras": [
    {
      "bearingDegrees": 180,
      "cameraActive": true,
      "cameraInMaintenance": false,
      "cleardayImageUrl": "",
      "currentImageObservationTime": "2024-09-16T21:47:21Z",
      "currentImageUrl": "https://d27xevwqdit1m8.cloudfront.net/current/mwos/rampart/2024-09-16T214721Z_180-DS0hfqJ6pu.jpg",
      "directionText": "South"
    },
    {
      "bearingDegrees": 90,
      "cameraActive": true,
      "cameraInMaintenance": false,
      "cleardayImageUrl": "",
      "currentImageObservationTime": "2024-09-16T21:47:20Z",
      "currentImageUrl": "https://d27xevwqdit1m8.cloudfront.net/current/mwos/rampart/2024-09-16T214720Z_90-Gab4sSXSFp.jpg",
      "directionText": "East"
    },
    {
      "bearingDegrees": 270,
      "cameraActive": true,
      "cameraInMaintenance": false,
      "cleardayImageUrl": "",
      "currentImageObservationTime": "2024-09-16T21:47:21Z",
      "currentImageUrl": "https://d27xevwqdit1m8.cloudfront.net/current/mwos/rampart/2024-09-16T214721Z_270-oHpg1qrHAf.jpg",
      "directionText": "West"
    },
    {
      "bearingDegrees": 0,
      "cameraActive": true,
      "cameraInMaintenance": false,
      "cleardayImageUrl": "",
      "currentImageObservationTime": "2024-09-16T21:47:20Z",
      "currentImageUrl": "https://d27xevwqdit1m8.cloudfront.net/current/mwos/rampart/2024-09-16T214720Z_0-2Hhds1RTWU.jpg",
      "directionText": "North"
    }
  ],
  "country": "US",
  "iataId": "RMP",
  "icaoId": "PRMP",
  "id": 2,
  "latitude": 65.51125,
  "longitude": -150.15225,
  "observations": [
    {
      "dewpointC": 5.5714,
      "humidityPct": 73.56,
      "observationTime": "2024-09-16T21:47:21Z",
      "precipIn": 0,
      "precipType": "No precipitation",
      "pressureHpa": 29.18,
      "rawText": "MWOS 2024-09-16T21:47:21.000Z Temp 10.1°C Winds From 262° at 2 knots (gusts at 5 knots)",
      "tempC": 10.0867,
      "wetbulbTempC": 7.9714,
      "windDirDegrees": "261.5672",
      "windGustKt": 5.2054,
      "windSpeedKt": 1.5335,
      "windsText": "From 262° at 2 knots (gusts at 5 knots)"
    },
    {
      "dewpointC": 5.6461,
      "humidityPct": 75.0186,
      "observationTime": "2024-09-16T21:37:04Z",
      "precipIn": 0,
      "precipType": "No precipitation",
      "pressureHpa": 29.18,
      "rawText": "MWOS 2024-09-16T21:37:04.000Z Temp 9.9°C Winds From 290° at 2 knots (gusts at 5 knots)",
      "tempC": 9.8698,
      "wetbulbTempC": 7.6969,
      "windDirDegrees": "290.3365",
      "windGustKt": 5.3117,
      "windSpeedKt": 2.382,
      "windsText": "From 290° at 2 knots (gusts at 5 knots)"
    },
    {
      "dewpointC": 5.86,
      "humidityPct": 77.3876,
      "observationTime": "2024-09-16T21:26:46Z",
      "precipIn": 0,
      "precipType": "No precipitation",
      "pressureHpa": 29.18,
      "rawText": "MWOS 2024-09-16T21:26:46.000Z Temp 9.6°C Winds From 346° at 2 knots (gusts at 6 knots)",
      "tempC": 9.6311,
      "wetbulbTempC": 7.7338,
      "windDirDegrees": "345.6836",
      "windGustKt": 5.5367,
      "windSpeedKt": 2.3088,
      "windsText": "From 346° at 2 knots (gusts at 6 knots)"
    }
  ],
  "siteName": "Rampart MWOS",
  "state": "AK"
}
