import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react';
import { css } from '@emotion/css';
import bgImage from '../assets/hero.avif';

export default function SignIn() {
  return (
    <ThemeProvider
      theme={{
        tokens: {
          colors: {
            primary: {
              // Used for Sign In button and Forgot Password
              80: '#2f65bf',
            },
          },
        },
      }}
    >
      <div className={style}>
        <Authenticator hideSignUp={true}>
          {/* {({ signOut, user }) => <div>{JSON.stringify(user)}</div>} */}
          {/* {({ signOut, user }) => <Navigate to="/map" replace={true} />} */}
        </Authenticator>
      </div>
    </ThemeProvider>
  );
}

const style = css({
  display: 'flex',
  position: 'absolute',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  background: `url(${bgImage}) no-repeat center center fixed`,
  backgroundSize: 'cover',
  '*': {
    fontFamily: 'Open Sans',
  },
});
