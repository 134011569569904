varying vec2 vUv;

uniform sampler2D image;
uniform float rotation;
uniform float vshift;
uniform float hshift;
uniform float brighten;
uniform float blueAdjust;
uniform float redAdjust;

// Axis F2105 Sensor
const float cameraHFoV = 108.0;
const float cameraVFoV = 58.0;

// Show alignment lines useful for tweaking the distortion/alignment
const bool SHOW_LINES = false;

vec4 WHITE = vec4(1.0, 1.0, 1.0, 1.0);
vec4 BLACK = vec4(0.0, 0.0, 0.0, 1.0);
vec4 GREEN = vec4(0.0, 1.0, 0.0, 1.0);
vec4 LBLUE = vec4(0.5, 0.5, 1.0, 1.0);

// Lens (un)distortion parameters
// In theory (and hopefully in practice) these should be constant
// for all the cameras/lenses
const float distortA = 0.;
const float distortB = 0.5;
const float distortC = -4.7;
const float distortD = -50.0;
// voffset is used to model off-center lens elements
const float voffset = -0.02;
// fadeCoeff is used to adjust the strength of the blending
// in the blending area of the images (the overlap area)
const float fadeCoeff = 50.;

mat2 rotate2d(float theta){
  return mat2(cos(theta), -sin(theta),
              sin(theta),  cos(theta));
}

vec2 brownConrady(vec2 uv, float a, float b, float c, float d)
{
    uv = uv * 2.0 - 1.0;	// brown conrady takes [-1:1]

    // Positive values of a,b,c,d give barrel distortion, negative give pincushion
    float r = sqrt(uv.x*uv.x + uv.y*uv.y);
    float r3 = pow(r, 3.0);
    float r5 = pow(r, 5.0);
    float r7 = pow(r, 7.0);
    uv.x *= 1.00 + a * r + b * r3 + c * r5 + d * r7 + 0.005;

    // Tangential distortion (due to off center lens elements)
    // is not modeled in this function, but if it was, the terms would go here

    uv = (uv * .5 + .5);	// restore -> [0:1]
    return uv;
}

void main(){
  vec2 uv = vec2(vUv);

  // Let's assume the lens element is slightly off center vertically
  // So we need to shift, distort, then unshift
  uv.y += voffset;
  uv = brownConrady(uv, distortA, distortB, distortC, distortD);
  uv.y -= voffset;

  // Flip uv.x (mirror it) so the view from inside the sphere
  // is not a mirror image, then squeeze uv based on the camera/lens
  // parameters so it occupies the correct area
  uv.x = 1.0 - uv.x;
  uv.x = uv.x * (360.0/cameraHFoV) - 1.166;
  uv.y = uv.y * (360.0/cameraHFoV) - 1.166;

  // Perform image rotation to help with alignment
  uv *= rotate2d(rotation);
  // Perform vertical shifting to help with alignment
  uv.y -= vshift;
  uv.x -= hshift;

  // Sample the texture at the undistorted point
  vec4 color = vec4(vec3(0.0), 1.0);
  color = texture(image, vec2(uv.x, uv.y));
  // Make ad-hoc adjustments to colors
  color *= brighten;
  color *= vec4(1.0, 1.0, blueAdjust, 1.0);
  color *= vec4(1.0, 1.0, redAdjust, 1.0);

  // Reduce vignetting
  vec2 uv2 = uv * 2.0 - 1.0;
  float r = sqrt(abs(uv2.x*uv2.x + uv2.y*uv2.y));
  color *= 1.0 + pow(r,3.6)/18.0;

  if (SHOW_LINES) {
    // Display vertical alignment lines for each quadrant
    // epsilon: (line thickness / 2)
    float eps = 0.00025;
    if (vUv.x <= 0.001 || vUv.x >= 0.999) {
      color = WHITE;
    } else if (vUv.x >= (0.25-eps) && vUv.x <= (0.25+eps)) {
      color = WHITE;
    } else if (vUv.x >= (0.5-eps) && vUv.x <= (0.5+eps)) {
      color = WHITE;
    } else if (vUv.x >= (0.75-eps) && vUv.x <= (0.75+eps)) {
      color = WHITE;
    }

    // More vertical alignment lines at regular spacing
    if (mod(vUv.x, .025) <= eps) {
      color = LBLUE;
    }

    // Display horizontal alignment line
    float epsY = 0.0005;
    if (vUv.y >= (0.5-epsY) && vUv.y <= (0.5+epsY)) {
      color = WHITE;
    }
    // Display line showing image overlap area
    if (vUv.x >= (0.375-eps) && vUv.x <= (0.375+eps)) {
      color = GREEN;
    }
    if (vUv.x >= (0.625-eps) && vUv.x <= (0.625+eps)) {
      color = GREEN;
    }
  }

  // Blend the image edges; the formulas below are just estimated
  // based on subjective visual appearance
  // Right side overlap area
  if (vUv.x < 0.375) {
    color.a *= pow(1.0 - (0.375 - vUv.x) * fadeCoeff, 0.7);
  }
  // Left side overlap area
  if (vUv.x > .625) {
    color.a *= pow(1.0 - (vUv.x - 0.625) * fadeCoeff, 0.7);
  }

  // Cut off / make transparent beyond image horizontal edge
  if (vUv.x <= (0.5-.15) || vUv.x >= (0.5+.15)) {
    color.a = 0.0;
  }
  // Cut off / make transparent beyond image vertical edge
  float extra = 0.005;
  if (vUv.y <= (0.5-.145+extra) || vUv.y >= (0.5+.15)) {
    color = BLACK;
  }


  gl_FragColor = color;
}
