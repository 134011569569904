import { create } from 'zustand';

export type FetchMode = 'single' | 'foveated' | 'all'

type State = {
  bearing: number;
  setBearing: (b: number) => void;
  altitude: number;
  setAltitude: (a: number) => void;
  fetchMode: FetchMode;
  setFetchMode: (a: FetchMode) => void;
}

export const useStore = create<State>()((set) => ({
  bearing: 0,
  setBearing: (bearing) => set(() => ({ bearing })),
  altitude: 0,
  setAltitude: (altitude) => set(() => ({ altitude })),
  fetchMode: 'foveated',
  setFetchMode: (fetchMode) => set(() => ({ fetchMode }))
}))
